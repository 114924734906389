'use strict'

const Articles = {
    init: function () {
        this.root = $('html, body')
        this.el = this.root.find('.block-rte')

        if(this.el.length === 0) return

		//this.getOffsets()

        if (this.el.length === 0) return

        //this.events()
    },

    //events: function () {
    //    this.links.on('click', (e) => this.scrollToSection(e))
    //    $(window).scroll(() => this.onScroll())
    //    $(window).resize(() => this.getOffsets())
    //},

    //getOffsets: function() {
    //    // Offset & positions
    //    this.articleOffset = this.el.offset().top
    //    this.articleEndOffset = this.articleOffset + this.el.height()
    //},

    //scrollToSection: function (e) {
    //    e.preventDefault()

    //    const target = $(e.currentTarget)
        
    //    this.root.animate({
    //        scrollTop: $(target.attr('href')).offset().top - 160
    //    }, 1000)
    //},

    //onScroll: function () {
    //    // 80 is for the 80px fixed header
    //    const windowOffset = $(window).scrollTop() + 100,
    //          diff = windowOffset - this.articleOffset

    //    if (windowOffset < this.articleOffset)
    //        this.nav.css('top', '0')

    //    if (windowOffset > this.articleOffset && diff < (this.nav.parent().height() - this.nav.height())) {
    //        this.nav.css('top', diff + 'px')
    //    }
    //}
}

$(document).ready(() => Articles.init())
