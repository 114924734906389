'use strict'

var Support = {
    init: function () {
        this.el = $('.support')
        this.btn = $('.article__contact')
        this.headerCta = $('.nav__actions').find('.button--cta')
        this.closeBtn = this.el.find('.button--round')

        this.events()
    },
    events: function () {
        this.btn.on('click', () => this.onClick())
        this.headerCta.on('click', () => this.onClick())
        this.closeBtn.on('click', () => this.onClose())
    },
    onClick: function () {
        this.el.hasClass('active') ? this.el.removeClass('active') : this.el.addClass('active')
    },

    onClose: function () {
        this.el.removeClass('active')
    }
}

$(document).ready(() => Support.init())