'use strict'

var Cases = {
    init: function () {
        this.el = $('.cases')

        if (this.el.length === 0) return

        this.categories = this.el.find('.cases__categories')
        this.buttons = this.el.find('.cases__filter-button')
        this.items = this.el.find('.cases__item')

        this.events()
        if (this.categories.length > 0) {
            if (this.categories.attr('data-active') !== '')
                var category = this.categories.attr('data-active').toLowerCase()
            else
                var category = 'alle'
        }

        this.filter(category)
    },

    events: function () {
        this.buttons.on('click', (e) => this.onFilterClick(e))
    },

    onFilterClick: function (e) {
        let target = $(e.currentTarget),
            category = target.attr('data-category').toLowerCase()

        this.buttons.removeClass('active')
        target.addClass('active')

        this.filter(category)
    },

    filter: function (category) {
        this.items.each(function () {
            let target = $(this)

            target.attr('data-categories').toLowerCase().indexOf(category) > -1 || category == "alle" ? target.removeClass('hide') : target.addClass('hide')
        })
    }
}

$(document).ready(() => Cases.init())