'use strict'

// var mc = require('hammerjs');

var Hero = {
    init: function () {
        if ($('.hero').length === 0 || $('.hero__video').length > 0)
            return

        this.el = $('.hero')
        this.arrow = this.el.find('.hero__arrow')
        this.container = this.el.find('.hero__container');
        this.list = this.el.find('.hero__list')
        this.items = this.el.find('.hero__item')
        this.images = this.items.find('img')
        this.left = this.el.find('.hero__pager--left')
        this.right = this.el.find('.hero__pager--right')
        this.pagers = this.el.find('.hero__pager')
        this.buttons = $('.hero__buttons')
        this.index = 0
        this.position = 0

        this.events()
        // this.calculatePosition()
        this.hidePagers()
        this.onClick()
        this.onResize()
        this.buttonSize()
        // this.calculateHeroHeight()
    },
    events: function () {
        this.arrow.on('click', () => this.onArrowClick())
    },
    onArrowClick: function () {
        var self = this
        this.nextSection = this.el.next()

        $('html, body').animate({
            scrollTop: self.nextSection.offset().top - 80
        }, 500)
    },
    onClick: function () {
        var self = this;

        this.pagers.on('click', function (e) {
            var direction = $(e.currentTarget).attr('data-direction')

            if (direction === "left" && self.index > 0) {
                self.position += self.itemWidth
                self.index--
            }
            if (direction === "right" && self.index < self.items.length - 1) {
                self.position -= self.itemWidth
                self.index++
            }

            self.changeSlide()
        })
    },

    calculateHeroHeight: function() {
        this.el.height(this.images.height())
    },

    calculatePosition: function () {
        this.items.width($('.main').width());

        this.itemWidth = this.items.width();
        this.conWidth = 0;

        for (var i = 0; i < this.items.length; i++) {
            var el = $(this.items[i]);
            this.conWidth += el.width();

            el.css('transform', 'translateX(' + (this.itemWidth * i) + 'px)');
        }

        this.container.width(this.conWidth);
    },
    hidePagers: function () {
        this.index > 0 ? this.left.addClass('show') : this.left.removeClass('show');
        this.index < this.items.length - 1 ? this.right.addClass('show') : this.right.removeClass('show');
    },
    changeSlide: function () {
        this.container.css('transform', 'translateX(-' + (this.itemWidth * this.index) + 'px)');
        this.hidePagers()
    },
    onResize: function () {
        var self = this;

        $(window).resize(function () {
            // self.calculatePosition()
            // self.calculateHeroHeight()
            self.container.css('transform', 'translateX(-' + (self.itemWidth * self.index) + 'px)');
        });
    },

    buttonSize: function () {
        if(!$('.hero__nav').hasClass('hero__front')) return

        const buttons = this.buttons.find('div a')
        var maxWidth = 0

        buttons.each(function () {
            let el = $(this)

            if (el.width() > maxWidth) {
                maxWidth = el.width()
            }
        })

        buttons.each(function () {
            $(this).width(maxWidth)
        })
    }
}

$(window).load(() => Hero.init())