const $ = require('jquery')
const svg4everybody = require('svg4everybody')
const priorityNav = require('priority-nav')
// const parentElement = require('node.parentelement')
const customEvent = require('custom-event-polyfill')
const objectFitImages = require('object-fit-images')
const objectFitVideos = require('object-fit-videos')

import "picturefill";
import lazySizes from "lazysizes";

require('./components/megamenu.js')
require('./components/video.js')
require('./components/button.js')
require('./components/cookie.js')
require('./components/submit.js')

// Modules
require('./modules/articles.js')
require('./modules/header.js')
require('./modules/hero.js')
require('./modules/search.js')
require('./modules/employees.js')
require('./modules/videoSection.js')
require('./modules/support.js')
require('./modules/newsletter.js')
require('./modules/cases.js')
require('./modules/articles.js')
require('./modules/slider.js')
require('./modules/carousel.js')
require('./modules/lightbox.js')
require('./modules/scroll')

svg4everybody()

objectFitImages()
objectFitVideos()

priorityNav.init({
	mainNavWrapper: '[data-priority-nav="wrapper"]',
	mainNav: '[data-priority-nav="nav"]',
	navDropdownToggleClassName: 'nav__more',
	navDropdownLabel: 'Mere',
	offsetPixels: 0, // increase to decrease the time it takes to move an item.
	throttleDelay: 50, // this will throttle the calculating logic on resize because i'm a responsible dev.
})

if (document.querySelectorAll('.slider').length) {
	for (let i = 0; i < document.querySelectorAll('.slider').length; i++) {
		let item = document.querySelectorAll('.slider')[i].querySelector('.tiny-slider')
		let controls = document.querySelectorAll('.slider')[i].querySelector('.tiny-slider__controls')

		var slider = tns({
			container: item,
			nav: false,
			controlsContainer: controls
		})
	}
}
