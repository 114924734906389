'use strict'

var Newsletter = {
    init: function () {
        this.el = $('.newsletter')
        this.form = this.el.find('form')
        this.zip = this.el.find('.newsletter__input[name="newsLetterZip"]')
        this.submit = this.el.find('.button--cta')
        this.error = this.el.find('.zip-error')

        this.events()
    },
    events: function () {
        this.zip.on('keyup', (e) => this.validate(e))
    },
    validate: function (e) {
        let target = $(e.currentTarget),
            value = target.val()

        const regEx = new RegExp('^[0-9]+$')

        if (value.length !== 4 || !regEx.test(value)) {
            this.submit.attr('disabled', true)
            this.error.addClass('show')
        }
        else {
            this.submit.attr('disabled', false)
            this.error.removeClass('show')
        }
    }
}

$(document).ready(() => Newsletter.init())