'use strict'

const videoSection = {
    aspectRatio: 1.78,

    init: function () {
        const self = this

        this.elements = document.querySelectorAll('.testimonial--video')
        
        this.videos = document.querySelectorAll('.testimonial--video video')

        if (this.elements.length === 0) return

        for (var i = 0; i < this.elements.length; i++) {
            //this.calcSize(this.elements[i])

           $(this.elements[i]).find('video').prop('muted', true)
        }

        this.events()
    },

    events: function () {
        const self = this

        for (var i = 0; i < this.videos.length; i++) {
            this.videos[i].addEventListener('click', (e) => this.toggleSound(e.currentTarget))
        }

        for (var h = 0; h < document.querySelectorAll('.toglgesound').length; h++) {
            let btn = document.querySelectorAll('.toglgesound')[h]

            var video = btn.parentElement.querySelector('video')
            btn.addEventListener('click', () => {
                this.toggleSound(video)
            })
        }

        window.onresize = function () {
           for (var i = 0; i < self.elements.length; i++) {
               self.calcSize(self.elements[i])
           }
        }
    },

    calcSize: function (el) {
        let video = el.querySelector('video'),
            videoWidth = video.parentElement.offsetWidth,
            videoHeight = videoWidth / this.aspectRatio

        video.setAttribute('style', 'height: ' + videoHeight + 'px');
    },

    toggleSound: function (e) {
        let target = e

        if ($(target).prop('muted')) {
            $(target).prop('muted', false);
            $(target).addClass('unmute-video');

        } else {
            $(target).prop('muted', true);
            $(target).removeClass('unmute-video');
        }

        //target.hasAttribute('muted') ? target.removeAttribute('muted') : target.setAttribute('muted', true)
    }
}

$(document).ready(() => videoSection.init())