'use strict'

var SubmitForm = {
	init: function () {
		this.el = $('form')

		if (!this.el.length) return

		this.events()
	},

	events: function () {
		$('form').submit(function (event) {

			let formErrors = $(this).find('.input-validation-error');
			let formLoader = this.querySelector('.form-loader');

			if (!formErrors.length) {
				formLoader.style.display = "block";
			}
		})
	}
}

$(document).ready(() => SubmitForm.init())
