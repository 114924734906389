'use strict'

const megamenu = {
	init: function () {
		const self = this
		const megamenuButton = document.querySelectorAll("[megamenu-button]")

		for (let i = 0; i < megamenuButton.length; i++) {
			const button = megamenuButton[i]

			button.setAttribute("href", button.dataset.href)
			button.addEventListener("click", event => this.onClick(event))
		}

		window.onload = function () {
			const moreButton = document.querySelector(".nav__more")

			if (moreButton != null) {
				moreButton.addEventListener("click", event => self.onMoreClick(event))
			}
		}

		const mediaQuery = window.matchMedia("(max-width: 990px)");
		mediaQuery.addListener(this.matchesMediaQuery)
	},

	onClick: function (event) {
		const isInDropdown = event.currentTarget.closest(".nav__dropdown")

		let topNavigation = document.querySelector('.top-navigation');
		let headerInner = document.querySelector('.header__inner');
		let headerInnerParent = headerInner.parentElement;


		if (isInDropdown == null) {
			event.preventDefault()

			const button = event.currentTarget
			const megamenu = document.querySelector(`[megamenu='${button.dataset.id}']`)
			if (megamenu.classList.contains("megamenu--active")) {
				button.parentElement.classList.remove("nav__item--active")
				megamenu.classList.remove("megamenu--active")

				if (headerInnerParent.classList.contains('dark')) {
					topNavigation.classList.remove('bg-white', 'text-black')
					topNavigation.classList.add('text-white', 'bg-transparent')

					headerInnerParent.classList.add('text-white', 'bg-transparent')
					headerInnerParent.classList.remove('bg-white', 'text-black')
				}

				if (headerInnerParent.classList.contains('light')) {
					topNavigation.classList.remove('bg-white')
					topNavigation.classList.add('bg-transparent', 'text-black')

					headerInnerParent.classList.remove('bg-white')
					headerInnerParent.classList.add('bg-transparent')
				}

			} else {
				this.clearMegamenus()
				this.clearMegamenuButtons()

				button.parentElement.classList.add("nav__item--active")
				megamenu.classList.add("megamenu--active")

				if (headerInnerParent.classList.contains('dark')) {
					topNavigation.classList.remove('text-white', 'bg-transparent')
					topNavigation.classList.add('bg-white', 'text-black')

					headerInnerParent.classList.remove('text-white', 'bg-transparent')
					headerInnerParent.classList.add('bg-white', 'text-black')
				}
				if (headerInnerParent.classList.contains('light')) {
					topNavigation.classList.remove('bg-transparent')
					topNavigation.classList.add('bg-white', 'text-black')

					headerInnerParent.classList.remove('bg-transparent')
					headerInnerParent.classList.add('bg-white', 'text-black')
				}
			}

			
		}
	},

	onMoreClick: function (event) {
		event.preventDefault()

		this.clearMegamenus()
		this.clearMegamenuButtons()
	},

	matchesMediaQuery: function (event) {
		if (event.matches) {
			const megamenus = document.querySelectorAll("[megamenu]")
			for (let i = 0; i < megamenus.length; i++) {
				megamenus[i].classList.remove("megamenu--active")
			}
			const megamenuButtons = document.querySelectorAll("[megamenu-button]")
			for (let i = 0; i < megamenuButtons.length; i++) {
				megamenuButtons[i].parentElement.classList.remove("nav__item--active")
			}
		}
	},

	clearMegamenus: function () {
		const megamenus = document.querySelectorAll("[megamenu]")
		for (let i = 0; i < megamenus.length; i++) {
			megamenus[i].classList.remove("megamenu--active")
		}
	},

	clearMegamenuButtons: function () {
		const megamenuButtons = document.querySelectorAll("[megamenu-button]")
		for (let i = 0; i < megamenuButtons.length; i++) {
			megamenuButtons[i].parentElement.classList.remove("nav__item--active")
		}
	}
}

export default megamenu.init();
