import { tns } from "../../node_modules/tiny-slider/src/tiny-slider"

const carousel = {
  init: function () {
		const element = document.querySelector(".carousel__body")

		if (element != null) {
			tns({
				container: '.carousel__body',
				slideBy: 1,
				items: 1,
				autoplay: false,
				loop: false,
				swipeAngle: false,
				controlsContainer: '.navigation-buttons',
				nav: false,
				gutter: 32,
				autoHeight: true,
				responsive: {
					768: {
						items: 2
					},
					991: {
						items: 3
					}
				}
			})
		}
  }
}

export default carousel.init();
