'use strict'

var Newsletter = {
    init: function () {
        if ($('.video').length === 0)
            return

        this.el = $('.video')

        this.onResize()
        this.calcSize()
    },
    calcSize: function () {
        var self = this

        this.parentWidth = this.el.closest('.article').width()
        this.aspectHeight = (this.parentWidth / 1.779)

        this.el.each(function () {
            $(this).width(self.parentWidth)
            $(this).height(self.aspectHeight)
        })
    },
    onResize: function () {
        $(window).on('resize', () => this.calcSize())
    }
}

$(document).ready(() => Newsletter.init())