'use strict'

var Button = {
    init: function () {
        this.el = $('.button--back')

        if (!this.el.length) return

        this.events()
    },

    events: function () {
        // this.el.click((e) => {
        //     window.location.href = "/"
        // })

        this.el.click(function () {
            window.location.href = "/"
        })
    }
}

$(document).ready(() => Button.init())