'use strict'

var lightbox = {
    init: function () {
        this.images = [] //list of all links
        this.body = document.querySelector("body")
        this.value = 0 //value of transition

        //set buttons handler
        this.buttonRight = document.querySelector(".lightbox-button-right")
        this.buttonLeft = document.querySelector(".lightbox-button-left")

        //button handler
        this.buttonRight.addEventListener("click", () => this.slideRight())
        this.buttonLeft.addEventListener("click", () => this.slideLeft())

        this.elements = document.querySelectorAll(".lightbox") //get all elements
        this.transform = 100 //set transform value

        for (var i = 0; i < this.elements.length; i++) {
            const elements = this.elements[i]

            //push all links inside array
            this.images.push(elements.dataset.big)

            //and set click event
            elements.addEventListener("click", (e) => this.onImageClick(e))
        }
    },

    onImageClick: function (e) {
		e.preventDefault()

		const lightboxParent = e.target.closest('.lightbox');
		const lightboxImage = lightboxParent.querySelector('.lightbox__image');

		lightboxImage.classList.add("lightbox-clicked")

        //hide overflow
        this.body.style.overflow = "hidden"

        //show lightbox
        document.querySelector(".lightbox-background").style.display = "block"

        //get slides container
        this.slidesBox = document.querySelector(".lightbox-slides")

        //get loading Animation
        this.loadingAnimation = document.querySelector(".lightbox-loader")

        if (this.elements.length == 1) {
            this.buttonRight.style.display = "none"
            this.buttonLeft.style.display = "none"
        }

        //Run next Function
        this.putAllImages()

        document.querySelector(".lightbox-exit").addEventListener("click", () => this.onLightboxClose())
    },

    onLightboxClose: function () {
        //set overflow for body
        this.body.style.overflow = "auto"

        //hide lightbox
        document.querySelector(".lightbox-background").style.display = "none"

        //CLEAN
        this.clean()
    },

    putAllImages: function () {
        this.elements = document.querySelectorAll(".lightbox") //get all elements
        for (var i = 0; i < this.elements.length; i++) {
            const elements = this.elements[i]

            if (elements.querySelector("picture img").classList.contains("lightbox-clicked")) {
                this.left = -(i * 100)
            }

            //show loading anim
            this.loadingAnimation.style.opacity = 1
            this.slidesBox.style.opacity = 0

			var self = this

            setTimeout(function () {
                //hide loading anim
                self.loadingAnimation.style.opacity = 0
                self.slidesBox.style.opacity = 1
            }, 125 * this.elements.length)

            //put new slide
            this.slidesBox.innerHTML += '<div class="lightbox-slide" style="left:' + i * 100 + '%"><img src="' + elements.dataset.big + '">' + this.caption + '</div>'

            if (i + 1 === this.elements.length) {
                this.value = this.left

                if (this.value == this.left) {
                    this.transform = 0

                    this.slideRight()

                    this.transform = 100
                }
            }
        }
	},

	slideLeft: function () {
		if (this.value >= 0) {
			//do nothing
		} else {
			this.buttonRight.classList.remove("lightbox-inactive");
			this.value += this.transform;
			this.slidesBox.style.transform = "translate(" + this.value + "%, 0)";

			if (this.value >= 0) {
				this.buttonLeft.classList.add("lightbox-inactive");
			}
		}
	},

	slideRight: function () {
		console.log(this.value)
		if (-this.value < (this.elements.length * 100) - this.transform) {
			this.value -= this.transform;

			if (-this.value > 0 || -this.value > -0) {
				this.buttonLeft.classList.remove("lightbox-inactive");
			}
			else {
				this.buttonLeft.classList.add("lightbox-inactive");
			}

			this.slidesBox.style.transform = "translate(" + this.value + "%, 0)";

			if (-this.value >= (this.elements.length * 100) - 100) {
				this.buttonRight.classList.add("lightbox-inactive");
			}
			else {
				this.buttonRight.classList.remove("lightbox-inactive");
			}
		}
	},

    clean: function () {
        /* RESET VARIABLES */
        this.elements = 0 //holder for all images
        this.width = 0 //length of all container
        this.images = [] //list of all links
        this.slidesBox = undefined
        this.value = 0 //value of transition
        this.transform = undefined
        this.left = 0
        if (document.querySelector(".lightbox-clicked")) document.querySelector(".lightbox-clicked").classList.remove("lightbox-clicked")
        this.loadingAnimation = undefined

        const slides = document.querySelectorAll(".lightbox-slide")
        slides.forEach(slide => {
            slide.remove()
        })
    },

    
}

export default lightbox.init()
