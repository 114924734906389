'use strict'

var Employees = {
	init: function () {
		this.el = $('.employees')

		if (this.el.length === 0) return

		this.select = this.el.find('select')
		this.members = this.el.find('.employees__item')
		this.search = this.el.find('.employees__search')

		this.obj = {
			"city": "",
			"department": "",
			"search": ""
		}

		this.events()
	},
	events: function () {
		this.select.on('change', (e) => this.onInput(e))
		this.search.on('keyup', (e) => this.onInput(e))
	},
	onInput: function (e) {
		var self = this

		this.select.each(function () {
			let target = $(this),
				type = target.data('filter'),
				attr = self.members.attr('data-' + type),
				value = target.val().toLowerCase()

			if (type == "cities")
				self.obj.city = value

			if (type == "department")
				self.obj.department = value
		})

		this.filter(e)
	},
	filter: function (e) {
		var self = this
		const target = $(e.currentTarget)
		this.value = target.val()
		this.isSearch = target.hasClass('employees__search')

		if (this.isSearch)
			this.obj.search = this.value

		this.members.each(function () {
			let el = $(this),
				cities = el.attr('data-cities'),
				departments = el.attr('data-department'),
				name = el.find('.title'),
				title = el.find('.employees__title'),
				show = false,
				email = el.find('a[data-filter-param="email"]')

			if (self.obj.search.length) {
				let nameMatch = name[0].innerHTML.toLowerCase().indexOf(self.obj.search.toLowerCase()),
					titleMatch = title[0].innerHTML.toLowerCase().indexOf(self.obj.search.toLowerCase()),
					emailMatch = email[0].innerHTML.toLowerCase().indexOf(self.obj.search.toLowerCase())

				if (nameMatch > -1 || titleMatch > -1 || emailMatch > -1) {
					if (departments.indexOf(self.obj.department) > -1 && cities.indexOf(self.obj.city) > -1) {
						show = true
					}
				}
			}

			else {
				if ((departments.indexOf(self.obj.department) > -1 && cities.indexOf(self.obj.city) > -1)) {
					show = true
				}
			}

			show ? el.removeClass('hide') : el.addClass('hide')
		})
	}
}

$(document).ready(() => Employees.init())
