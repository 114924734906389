'use strict'

var Cookies = {
    init: function () {
        if ($('.cookie').length === 0)
            return

        this.el = $('.cookie')
        this.button = this.el.find('.button')

        this.button.on('click', (e) => this.onClick(e))
    },

    onClick: function (e) {
        var button = e.currentTarget

        // prevent default event
        e.preventDefault();

        // set cookie local
        document.cookie = "accept-cookies=1; expires=" + new Date(new Date().getFullYear() + 100, 1, 1).toUTCString() + "path=/";

        // remove banner
        var banner = button.parentNode;
        button.parentNode.parentNode.removeChild(banner);
    }
}

$(document).ready(() => Cookies.init())