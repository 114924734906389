const slider = {
  init: function () {
    for (let i = 0; i < document.querySelectorAll('.slider').length; i++) {
      let item = document.querySelectorAll('.slider')[i]
      let zoomButton = item.querySelector('.tiny-slider__zoom')
  
      this.events(item, zoomButton, i)
    }
  },

  events: function (item, zoomButton, i) {
    zoomButton.addEventListener('click', () => this.onZoom(item, zoomButton, i))
  },

  onZoom: function (item, zoomButton, i) {
    const activeItem = item.querySelector('.tns-slide-active')
    const img = activeItem.querySelector('img').src

    this.overlay = document.createElement('div')

    this.overlay.classList.add('modal')
    document.body.classList.add('lock')

    setTimeout(() => {
      this.overlay.classList.add('show')
    }, 100)

    const inner = document.createElement('div')
    inner.classList.add('modal__inner')

    const modalImg = document.createElement('img')
    modalImg.classList.add('modal__img')

    modalImg.src = img

    const controls = document.createElement('div')
    controls.classList.add('tiny-slider__controls')
    
    const buttonNext = document.createElement('button')
    const buttonPrev = document.createElement('button')

    buttonNext.classList.add('button', 'button--round', 'button--next')

    buttonNext.innerHTML = '<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/svg/svg.svg#icon-chevron"></use></svg>';
    buttonPrev.innerHTML = '<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/svg/svg.svg#icon-chevron"></use></svg>';

    buttonPrev.classList.add('button', 'button--round', 'button--prev')

    controls.appendChild(buttonPrev)
    controls.appendChild(buttonNext)

    inner.appendChild(modalImg)
    inner.appendChild(controls)

    this.overlay.appendChild(inner)

    document.body.appendChild(this.overlay)

    this.overlay.addEventListener('click', (e) => this.onClose(e))
    buttonNext.addEventListener('click', (e) => this.change(e, activeItem, item, modalImg))
    buttonPrev.addEventListener('click', (e) => this.change(e, activeItem, item, modalImg))
  },

  change (e, activeItem, item, modalImg) {
    const target = e.currentTarget
    var sibling
    var slides = item.querySelectorAll('.tns-item')
    var active
    var index
    var newIndex
    var arr = []
    
    for (let key of slides.keys()) {
      let slide = slides[key]

      arr.push(slide)

      if (slide.classList.contains('tns-slide-active')) {
        slide.classList.remove('tns-slide-active')
        active = slide

        index = key
        newIndex = key
      }
    }

    
    if (target.classList.contains('button--prev')) {
      newIndex--
    } else {
      newIndex++
    }

    if (newIndex > arr.length -2) {
      newIndex = 1
    }

    if (newIndex < 0) {
      newIndex = arr.length -2
    }

    arr[newIndex].classList.add('tns-slide-active')

    modalImg.src = arr[newIndex].querySelector('img').src

  },

  onClose: function (e) {
    if (e.target.closest('.modal__inner') === null && e.target !== document.querySelector('.modal__inner')) {
      this.overlay.remove()
      document.body.classList.remove('lock')
    }
  }
}

$(window).load(() => slider.init())