
const priorityNavs = require('priority-nav')

const Search = {
  init: function () {
    this.el = document.querySelector('.search')
    
    this.btn = this.el.querySelector('.search__toggle')

    this.events()
  },

  events: function () {
    this.btn.addEventListener('click', () => {
      this.toggleSearch()
    })
  },

  toggleSearch: function () {
    this.el.classList.contains('search--visible') ? this.el.classList.remove('search--visible') : this.el.classList.add('search--visible')

    setTimeout(() => {
      priorityNavs.doesItFit(document.querySelector('.nav'))
    },100)

    if (this.el.classList.contains('search--visible'))
      $('.search__input').focus()
  }
}

$(document).ready(() => Search.init())