'use strict'

let Scroll = {
	init: function () {
		document.addEventListener('scroll', function () {
			let scrollPos = window.scrollY;
			let headerInner = document.querySelector('.header__inner');
			let headerInnerParent = headerInner.parentElement;
			const megamenu = document.querySelector('.megamenu--active');

			if (scrollPos >= 56) {
				if (headerInnerParent.classList.contains('dark')) {
					headerInnerParent.classList.remove('text-white', 'bg-transparent')
					headerInnerParent.classList.add('bg-white', 'text-black', 'shadow-sm')
				}
				if (headerInnerParent.classList.contains('light')) {
					headerInnerParent.classList.remove('bg-transparent')
					headerInnerParent.classList.add('bg-white', 'text-black', 'shadow-sm')
				}

			} else if (scrollPos < 56 && !megamenu) {
				if (headerInnerParent.classList.contains('dark')) {
					headerInnerParent.classList.add('text-white', 'bg-transparent')
					headerInnerParent.classList.remove('bg-white', 'text-black', 'shadow-sm')
				}

				if (headerInnerParent.classList.contains('light')) {
					headerInnerParent.classList.remove('bg-white')
					headerInnerParent.classList.add('bg-transparent', 'shadow-sm')
				}
			}

			if (scrollPos < 56) {
				if (headerInnerParent.classList.contains('light')) {
					headerInnerParent.classList.remove('shadow-sm')
				}
			}

			if (window.innerWidth > 1080) {
				
			}
		});
	}
}

Scroll.init();
