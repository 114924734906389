'use strict'

var Header = {
	init: function () {
		this.el = $('.header')
		this.main = $('.main')
		this.body = $('body')
		this.content = $('#content')
		this.mobileNav = $('.mobile-nav:not(.hero--nav)')
		this.heroNav = $('.hero--nav')
		this.mobileToggleBtn = $('.mobile-nav__toggle')
		this.mobileItems = this.mobileNav.find('.mobile-nav__list__item button')
		this.heroToggleBtn = $('.hero__options').find('button')
		this.offset = 0;

		$('.hidden-nav').hide()

		this.events()
	},

	events: function () {
		this.mobileToggleBtn.on('click', () => this.mobileToggle())
		this.mobileItems.on('click', (e) => this.mobileToggleLevel(e))
		this.heroToggleBtn.on('click', (e) => this.heroToggle())
	},

	mobileToggle: function () {
		if (this.mobileToggleBtn.hasClass('active')) {
			this.mobileToggleBtn.removeClass('active')

			if ($(window).scrollTop() === 0) this.el.removeClass('scroll')

			this.main.removeClass('lock')
			this.body.removeClass('lock')
			this.heroNav.removeClass('active')
			this.content.css('top', '0px')

			this.mobileNav.removeClass('active')

			$(window).scrollTop(this.offset)
		}
		else {
			this.offset = $(window).scrollTop()
			this.mobileToggleBtn.addClass('active')
			this.mobileNav.addClass('active')

			this.content.css('top', -this.offset + 'px')

			this.main.addClass('lock')
			this.body.addClass('lock')

			if (!this.el.hasClass('scroll')) this.el.addClass('scroll')
		}

		if (this.el.hasClass('scroll--locked')) this.el.addClass('scroll')
	},

	heroToggle: function () {
		if (this.mobileToggleBtn.hasClass('active')) {
			this.mobileToggleBtn.removeClass('active')

			if ($(window).scrollTop() === 0) this.el.removeClass('scroll')

			this.main.removeClass('lock')
			this.content.css('top', '0px')

			this.heroNav.removeClass('active')

			$(window).scrollTop(this.offset)
		}
		else {
			this.offset = $(window).scrollTop()
			this.mobileToggleBtn.addClass('active')
			this.heroNav.addClass('active')

			this.content.css('top', -this.offset + 'px')

			this.main.addClass('lock')

			if (!this.el.hasClass('scroll')) this.el.addClass('scroll')
		}
	},

	mobileToggleLevel: function (e) {
		let target = $(e.currentTarget)[0]
		let parent = $(target).parent().parent()

		!parent.hasClass('active') ? parent.addClass('active') : parent.removeClass('active')
	}
}

$(document).ready(() => Header.init())
